@use '../settings' as *;

$columns: 12;

.inline-grid {
    display: inline-grid;
    grid-template-columns: repeat($columns, 1fr);
}

.grid {
    display: grid;
    grid-template-columns: repeat($columns, 1fr);
}

@for $i from 1 through ($columns + 1) {
    .from-#{$i} {
        grid-column-start: $i;
    }
    .to-#{$i} {
        grid-column-end: $i;
    }
}
