.terms-file-icon {
    --size: var(--space-scale-m);
    width: var(--size);
    height: var(--size);
    display: inline-block;
    fill: var(--color-decoration);
}

.terms-file-link {
    display: flex;
    align-items: center;
}

.terms-file-link-inner {
    margin-left: var(--space-scale-s);
}
