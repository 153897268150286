.agile-header {
    position: relative;
    min-height: 100vh;
    padding-top: var(--space-scale-l5);
    padding-bottom: var(--space-scale-l5);
}

.agile-header.grid {
    align-items: end;
    align-content: end;
}

.agile-header-icon {
    --size: var(--space-scale-m);

    width: var(--size);
    height: var(--size);
    grid-column: 1 / -1;

    fill: var(--color-decoration);
}

.agile-header-heading,
.agile-header-line {
    grid-column: 1 / span 12;
    margin-top: var(--space-scale-l2);
}

.agile-header-scroll {
    display: none;
}

.agile-header-scroll {
    grid-row: 5;
    grid-column: 1 / span 12;
    margin-top: var(--space-scale-l);
    align-items: center;
}

.agile-header-scroll-text {
    margin-left: var(--space-scale-s3);
}

.agile-header-arrow {
    --size: var(--space-scale-s);
    display: block;
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration);
}

.agile-header-button {
    margin-left: auto;
}

@media (min-height: 56rem) {
    .agile-header {
        padding-bottom: var(--space-scale-m);
    }

    .agile-header-line {
        display: block;
        grid-row: 3;
        height: 1px;
        align-self: center;
        max-width: none;
        overflow: visible;

        path {
            fill: none;
            stroke: var(--color-decoration);
            stroke-width: 1px;
        }
    }

    .agile-header-scroll {
        display: flex;
        margin-top: var(--space-scale-l4);
    }
}

@media (min-height: 64rem) {
    .agile-header-scroll {
        margin-top: var(--space-scale-l5);
    }
}

@media (min-width: 48rem) {
    .agile-header-line {
        margin-top: var(--space-scale-s);
    }
}

@media (min-width: 48rem) and (min-height: 60rem) {
}

@media (min-width: 60rem) {
    .agile-header {
        min-height: calc(100vh - var(--type-leading-l2));
    }
}

@media (min-width: 60rem) and (max-height: 60rem) {
    .agile-header {
        padding-top: var(--space-scale-l);
        padding-bottom: var(--space-scale-m);
    }
    .agile-header-scroll {
        display: flex;
        margin-top: var(--space-scale-l4);
    }
    .agile-header-title {
        margin-top: 0;
    }
    .agile-header-icon {
        display: none;
    }
}

@media (min-width: 75rem) {
    .agile-header {
        padding-top: var(--space-scale-l);
    }
}

@media (min-width: 75rem) and (min-height: 60rem) {
    .agile-header-scroll {
        margin-top: var(--space-scale-l5);
    }
}
