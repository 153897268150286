.divider {
    width: 100%;
    height: 1px;
    grid-column: 1 / span 12;
    max-width: none;
    overflow: visible;

    path {
        fill: none;
        stroke: var(--color-decoration);
        stroke-width: 1px;
    }
}
