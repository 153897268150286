@use '../settings' as *;
@use '../mixins' as *;

.max-inline-size-s {
    max-width: calc(var(--measure) / 1.25);
}

.max-inline-size {
    max-width: var(--measure);
}

.max-inline-size-l {
    max-width: calc(var(--measure) * 1.25);
}

.max-inline-size-full {
    max-width: 100%;
}

.color-invert {
    --color-background: var(--color-background-invert);
    --color-heading: var(--color-heading-invert);
    --color-paragraph: var(--color-paragraph-invert);
    --color-decoration: var(--color-decoration-invert);
    background-color: var(--color-background);
    color: var(--color-paragraph);
}

.color-decoration {
    color: #FF005C;
}
