.service-header {
    position: relative;
    min-height: 100vh;
    padding-top: var(--space-scale-l5);
    padding-bottom: var(--space-scale-l);
}

.service-header.grid {
    grid-auto-rows: minmax(min-content, max-content);
    align-items: end;
    align-content: end;
}

.service-header-number,
.service-header-title,
.service-header-subtitle,
.service-header-button,
.service-header-line {
    grid-column: 1 / span 12;
}

.service-header-line {
    display: none;
}

.service-header-scroll {
    display: none;
}

.service-header-button {
    grid-column: 12 / span 1;
}

.service-header-line {
    display: block;
    grid-row: 3;
    height: 1px;
    align-self: center;
    max-width: none;
    overflow: visible;

    path {
        fill: none;
        stroke: var(--color-decoration);
        stroke-width: 1px;
    }
}

.service-header-subtitle,
.service-header-button,
.service-header-line {
    margin-top: var(--space-scale-s);
}

.service-header-scroll {
    grid-row: 4;
    grid-column: 1 / span 12;
    align-items: center;
    cursor: pointer;
}
.service-header-scroll-text {
    margin-left: var(--space-scale-s3);
}
.service-header-arrow {
    --size: var(--space-scale-s);
    display: block;
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration);
}

.service-header-number {
    display: none;
}

@media (min-height: 56rem) {
    .service-header {
        padding-bottom: var(--space-scale-m);
    }
    .service-header-line,
    .service-header-button,
    .service-header-scroll {
        margin-top: var(--space-scale-l);
    }
    .service-header-scroll {
        display: flex;
    }
}

@media (min-width: 48rem) {
    .service-header-title {
        grid-row: 2;
        max-width: 15ch;
        margin-top: var(--space-scale-l2);
    }
    .service-header-subtitle {
        grid-row: 4;
        grid-column: 6 / span 7;
    }
    .service-header-button {
        grid-row: 4;
        grid-column: 1 / span 6;
        align-self: start;
    }
    .service-header-scroll {
        grid-row: 1;
        grid-column: 1 / span 3;
    }
    .service-header-line {
        display: none;
    }
    .service-header-subtitle,
    .service-header-button {
        margin-top: var(--space-scale-m);
    }
    .service-header-number {
        display: block;
        grid-row: 2;
        grid-column: 12 / span 1;
        font-family: 'Neutral Face', sans-serif;
        font-size: var(--type-scale-l2);
        line-height: var(--type-scale-display);
        color: var(--color-decoration);
    }
}

@media (min-width: 48rem) and (min-height: 60rem) {
    .service-header-title {
        margin-top: var(--space-scale-l4);
    }
    .service-header-line {
        display: block;
        grid-row: 3;
    }
    .service-header-subtitle,
    .service-header-button {
        margin-top: var(--space-scale-l5);
    }
}

@media (min-width: 60rem) {
    .service-header {
        min-height: calc(100vh - var(--type-leading-l2));
    }
}

@media (min-width: 60rem) and (max-height: 60rem) {
    .service-header {
        padding-top: var(--space-scale-l2);
    }
    .service-header-line {
        display: block;
        grid-row: 3;
    }
    .service-header-subtitle,
    .service-header-button {
        margin-top: var(--space-scale-l3);
    }
}

@media (min-width: 75rem) {
    .service-header {
        padding-top: var(--space-scale-l2);
        padding-bottom: var(--space-scale-l2);
    }
    .service-header-title {
        margin-top: 0;
    }
    .service-header-subtitle {
        grid-column: 8 / span 5;
    }
    .service-header-subtitle,
    .service-header-button {
        margin-top: var(--space-scale-l3);
    }
    .service-header-scroll {
        display: none;
    }
}

@media (min-width: 60rem) and (min-height: 60rem) {
    .service-header {
        padding-bottom: var(--space-scale-m);
    }
    .service-header-button,
    .service-header-subtitle {
        margin-top: var(--space-scale-l4);
    }
    .service-header-line {
        margin-top: var(--space-scale-m);
    }
    .service-header-scroll {
        display: flex;
        grid-row: 5;
        grid-column: 1 / span 12;
        justify-self: end;
        margin-top: var(--space-scale-l3);
    }
}
