@use 'functions' as *;

$minor-second: 1.067;
$major-second: 1.125;
$minor-third: 1.2;
$major-third: 1.25;
$perfect-fourth: 1.333;
$augmented-fourth: 1.414;
$perfect-fifth: 1.5;
$minor-sixth: 1.6;
$golden: 1.618;
$major-sixth: 1.667;
$minor-seventh: 1.778;
$major-seventh: 1.875;
$octave: 2;
$major-tenth: 2.5;
$major-eleventh: 2.667;
$major-twelfth: 3;
$double-octave: 4;

$base: 8; // base scale on 8 pixels

$minTypeScale: $minor-third;
$maxTypeScale: $major-third;

$minSpaceScale: $minTypeScale;
$maxSpaceScale: $maxTypeScale;

//viewport in px
$minWidth: 400;
$maxWidth: 1600;

@function type-scale($from, $to: $from) {
    @return fluid-scale($base, $minWidth, $maxWidth, $minTypeScale, $maxTypeScale, $from, $to);
}

@function space-scale($from, $to: $from) {
    @return fluid-scale($base, $minWidth, $maxWidth, $minTypeScale, $maxTypeScale, $from, $to);
}

@mixin spacings {
    @each $name in (s3, s2, s, m, l, l2, l3, l4, l5) {
        @content($name);
    }
}

@mixin types {
    @each $name in (s2, s, m, l, l2, l3, l4) {
        @content($name);
    }
}

:root {
    //--color-background: #fffffe;
    //--color-heading: #2b2c34;
    //--color-paragraph: #2b2c34;
    //--color-primary: #6246ea;
    //--color-primary-text: #fffffe;
    //--color-secondary: #d1d1e9;
    //--color-tertiary: #e45858

    //--color-background: #fef6e4;
    //--color-heading: #001858;
    //--color-paragraph: #172c66;
    //--color-primary: #f582ae;
    //--color-primary-text: #001858;

    //--color-background: #fffffe;
    //--color-heading: #272343;
    //--color-paragraph: #2d334a;
    //--color-primary: #ffd803;
    //--color-primary-text: #272343;

    //--color-background: #0f0e17;
    //--color-heading: #fffffe;
    //--color-paragraph: #a7a9be;
    //--color-primary: #ff8906;
    //--color-primary-text: #fffffe;

    //--color-background: #232946;
    //--color-heading: #fffffe;
    //--color-paragraph: #b8c1ec;
    //--color-primary: #eebbc3;
    //--color-primary-text: #232946;

    //--color-background: #16161a;
    //--color-heading: #fffffe;
    //--color-paragraph: #94a1b2;
    //--color-primary: #7f5af0;
    //--color-primary-text: #fffffe;

    //--color-background: #fffffe;
    //--color-heading: #00214d;
    //--color-paragraph: #1b2d45;
    //--color-primary: #00ebc7;
    //--color-primary-text: #00214d;

    //--color-background: #252626;
    //--color-heading: #ff00a1;
    //--color-paragraph: #eeeeee;
    //--color-primary: #c1f20f;
    //--color-primary-text: #252626;

    //--color-background: #fffffe;
    //--color-heading: #1d1d1d;
    //--color-paragraph: #282828;
    //--color-primary: #ff4617;
    //--color-primary-text: #fffffe;

    //--color-background: #cea5ad;
    //--color-heading: #cbff00;
    //--color-paragraph: #cbff00;
    //--color-primary: #000000;
    //--color-primary-text: #fffffe;

    //--color-background: #f7ea49;
    //--color-heading: #000000;
    //--color-paragraph: #000000;
    //--color-primary: #000000;
    //--color-primary-text: #fffffe;

    //--color-background: #EEEEEE;
    //--color-heading: #00092C;
    //--color-paragraph: #00092C;
    //--color-primary: #B20600;
    //--color-primary-text: #EEEEEE;

    //--color-background: #000000;
    //--color-background: #5800FF;
    //--color-heading: #EEEEEE;
    //--color-heading: #E900FF;
    //--color-paragraph: #EEEEEE;
    //--color-primary: #FFC600;
    //--color-primary-text: #000000

    //--color-background: #E8FFC2;
    //--color-heading: #0E185F;
    //--color-paragraph: #0E185F;
    //--color-primary: #00FFDD;
    //--color-primary-text: #000000;

    //--color-background: #252525;
    //--color-heading: #6930C3;
    //--color-paragraph: #fffffe;
    //--color-primary: #80FFDB;
    //--color-primary-text: #000000;

    //--color-background: #F8F8F8;
    //--color-heading: #1E3163;
    //--color-paragraph: #111111;
    //--color-primary: #F037A5;
    //--color-primary-text: #000000;

    //--color-background: #EEEEEE;
    //--color-heading: #303841;
    //--color-paragraph: #303841;
    //--color-primary: #ffffff;
    //--color-primary-text: #D72323;

    //--color-background: #F0F5F9;
    //--color-heading: #1E2022;
    //--color-paragraph: #52616B;
    //--color-primary: #ffffff;
    //--color-primary-text: #1E2022;

    //--color-background: #E4F9F5;
    //--color-heading: #40514E;
    //--color-paragraph: #40514E;
    //--color-primary: #ffffff;
    //--color-primary-text: #1E2022;

    //--color-background: #EEEEEE;
    //--color-heading: #171717;
    //--color-paragraph: #444444;
    //--color-primary: #DA0037;
    //--color-primary-text: #171717;

    //--color-background: #EEEEEE;
    //--color-heading: rgba(0, 0, 0, 1);
    //--color-paragraph: rgba(0, 0, 0, 0.87);
    //--color-decoration: rgba(0, 0, 0, 0.87);

    //--color-background-invert: rgba(0, 0, 0, 1);
    //--color-heading-invert: rgba(255, 255, 255, 1);
    //--color-paragraph-invert: rgba(255, 255, 255, 0.87);
    //--color-decoration-invert: rgba(255, 255, 255, 0.87);

    --color-background: rgba(0, 0, 0, 1);
    --color-heading: rgba(255, 255, 255, 1);
    --color-paragraph: rgba(255, 255, 255, 0.7);
    --color-decoration: rgba(255, 255, 255, 0.7);

    --color-background-invert: #EEEEEE;
    --color-heading-invert: rgba(0, 0, 0, 1);
    --color-paragraph-invert: rgba(0, 0, 0, 0.87);
    --color-decoration-invert: rgba(0, 0, 0, 0.87);

    --color-blend: rgba(255, 255, 255, 1);

    --type-scale-s2: #{type-scale(0)};
    --type-scale-s: #{type-scale(2)};
    --type-scale-m: #{type-scale(3, 4)};
    --type-scale-l: #{type-scale(2, 6)};
    --type-scale-l2: #{type-scale(6, 8)};
    --type-scale-l3: #{type-scale(7, 10)};
    --type-scale-l3-long: #{type-scale(7, 9)};

    --type-scale-display: #{type-scale(7, 11)};
    --type-scale-display-long: #{type-scale(2, 7)};

    --type-leading-s2: #{type-scale(4)};
    --type-leading-s: #{type-scale(4)};
    --type-leading-m: #{type-scale(6, 7)};
    --type-leading-l: #{type-scale(7, 9)};
    --type-leading-l2: #{type-scale(8, 11)};
    --type-leading-l3: #{type-scale(9, 12)};
    --type-leading-l4: #{type-scale(9, 12)};

    --type-leading-display: #{type-scale(9, 12)};
    --type-leading-display-long: #{type-scale(8, 11)};

    --measure: 60ch;

    --space-scale-s3: #{space-scale(1)};
    --space-scale-s2: #{space-scale(4)};
    --space-scale-s: #{space-scale(6)};
    --space-scale-m: #{space-scale(8)};
    --space-scale-l: #{space-scale(10)};
    --space-scale-l2: #{space-scale(11)};
    --space-scale-l3: #{space-scale(12)};
    --space-scale-l4: #{space-scale(13)};
    --space-scale-l5: #{space-scale(16)};
}
