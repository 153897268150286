//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@font-face {
    font-family: 'Noto Sans';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Noto-Sans.woff2') format('woff2');
}

@font-face {
    font-family: 'Neutral Face';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/Neutral\ face/NeutralFace.otf') format('opentype');
}

@font-face {
    font-family: 'Neutral Face';
    font-weight: 700;
    src: url('../fonts/Neutral\ face/NeutralFace-Bold.otf') format('opentype');
}
