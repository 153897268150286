.testimonials-quote,
.testimonials-quote-text {
    position: relative;
    grid-column: span 12;
}

.testimonials-quote-number {
    font-family: 'Neutral Face';
    letter-spacing: 0.1em;
    grid-column: 12 / span 1;
    justify-self: end;
    color: var(--color-decoration);
}

.testimonials-quote-icon {
    --size: var(--space-scale-s);

    width: var(--size);
    height: var(--size);

    fill: var(--color-decoration);
}

@media (min-width: 60rem) {
    .testimonials-quote-text {
        grid-column: 2 / span 8;
    }

    .testimonials-quote-icon {
        grid-column: 1 / span 2;
    }

    .testimonials-quote-number {
        grid-column: 10 / span 3;
        align-self: end;
        line-height: var(--type-leading-l);
    }
}
