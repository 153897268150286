// reset all styles
*:where(:not(iframe, canvas, img, svg, video, textarea):not(
    animate,
    animateMotion,
    animateTransform,
    circle,
    clipPath,
    defs,
    desc,
    discard,
    ellipse,
    feColorMatrix,
    feComponentTransfer,
    feComposite,
    feConvolveMatrix,
    feDiffuseLighting,
    feDisplacementMap,
    feDistantLight,
    feDropShadow,
    feFlood,
    feFuncA,
    feFuncB,
    feFuncG,
    feFuncR,
    feGaussianBlur,
    feImage,
    feMerge,
    feMergeNode,
    feMorphology,
    feOffset,
    fePointLight,
    feSpecularLighting,
    feSpotLight,
    feTile,
    feTurbulence,
    filter,
    foreignObject,
    g,
    hatch,
    hatchpath,
    image,
    line,
    linearGradient,
    marker,
    mask,
    metadata,
    mpath,
    path,
    pattern,
    polygon,
    polyline,
    radialGradient,
    rect,
    script,
    set,
    stop,
    style,
    svg,
    switch,
    symbol,
    text,
    textPath,
    title,
    tspan,
    use,
    view
)) {
    all: unset;
    display: revert;
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    text-size-adjust: 100%;
    font-size: 100%;
}

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
        Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
        sans-serif;
    font-weight: 400;
    font-style: normal;
    text-align: left;
}

textarea {
    background: unset;
    border: unset;
    outline: unset;
    color: unset;
}
