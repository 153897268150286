@mixin headings() {
    .display,
    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
        @content;
    }
}

@mixin buttons() {
    button,
    .button,
    input[type='button'],
    input[type='submit'],
    input[type='reset'] {
        @content;
    }
}

/*
 *include sass variables as css custom properties
 *
 *usage:
 *
 *@include create-custom-properties((
 *    space-3xs: 1rem,
 *    space-2xs: 2rem,
 *    space-xs: 3rem
 *));
 */
@mixin create-custom-properties($scss_variables, $prefix: '') {
    @each $name, $value in $scss-variables {
        /*
         *generate prefix and name
         */
        @if $prefix != '' {
            // if prefix and name are there
            @if $name != '' {
                $name: #{$prefix}-#{$name};
            }

            // if only the prefix is there
            @else {
                $name: #{$prefix};
            }
        }

        // if only the name is there
        @else if $name != '' {
            $name: #{$name};
        }

        /*
         *read maps
         */
        @if type-of($value) == 'map' {
            @include create-custom-properties($value, $name);
        }

        /*
         *read lists
         */
        @else if type-of($value) == 'list' {
            $index: 0;
            @each $list-value in $value {
                --#{$name}-#{$index}: #{$list-value};

                $index: $index + 1;
            }
        }

        /*
         *read variables
         */
        @else {
            --#{$name}: #{$value};
        }
    }
}
