@use '../settings' as *;

@include spacings using ($name) {
    .mt-#{$name} {
        margin-top: var(--space-scale-#{$name});
    }
    .mr-#{$name} {
        margin-right: var(--space-scale-#{$name});
    }
    .mb-#{$name} {
        margin-bottom: var(--space-scale-#{$name});
    }
    .ml-#{$name} {
        margin-left: var(--space-scale-#{$name});
    }
    .mx-#{$name} {
        margin-left: var(--space-scale-#{$name});
        margin-right: var(--space-scale-#{$name});
    }
    .my-#{$name} {
        margin-top: var(--space-scale-#{$name});
        margin-bottom: var(--space-scale-#{$name});
    }
    .m-#{$name} {
        margin: var(--space-scale-#{$name});
    }
}
