.service-table {
    padding-top: var(--space-scale-l2);
    padding-bottom: var(--space-scale-l4);
}

.service-table-item {
    grid-column: 1 / span 12;
}

.service-table-icon {
    display: none;
}

.service-table-item-list {
    grid-column: 1 / span 12;
    list-style: none;
}

.service-table-item-list-item {
    line-height: var(--type-leading-m);
}

@media (min-width: 48rem) {
    .service-table-icon {
        --size: var(--space-scale-s);

        width: var(--size);
        height: var(--size);
        display: block;
        grid-column: 1 / span 1;
        fill: var(--color-decoration);
    }

    .service-table-item {
        grid-column: 2 / span 8;
    }
}

@media (min-width: 75rem) {
    .service-table {
        padding-top: var(--space-scale-l4);
        padding-bottom: var(--space-scale-l5);
    }
    .service-table-icon {
        grid-column: 1 / span 1;
    }

    .service-table-item {
        grid-column: 2 / span 4;
    }

    .service-table-icon:nth-of-type(2) {
        grid-row: 1;
        grid-column: 7 / span 1;
    }

    .service-table-item:nth-of-type(2) {
        grid-row: 1;
        grid-column: 8 / span 4;
    }
}
