.text-button {
    position: relative;
    display: inline-block;
    transform: translate3d(-2rem, -2rem, 0);
    width: min-content;
}

.text-button-circle {
    --size: 14rem;
	display: block;
    width: var(--size);
    height: var(--size);
    animation: rotate-text 26000ms linear infinite;

    text {
        fill: var(--color-heading);
        font-family: 'Neutral Face', sans-serif;
        font-size: 32px;
    }
    textPath {
        letter-spacing: 10.5px; /* Firefox needs this */
    }
}

.text-button-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    height: var(--type-leading-s);
    display: grid;
    justify-items: center;
    transform: translate3d(-50%, -50%, 0);
    overflow: hidden;
}

.text-button-inner-text,
.text-button-inner-text-hidden {
    grid-column: 1;
    grid-row: 1;
    font-size: var(--type-scale-s);
    line-height: var(--type-leading-s);
    letter-spacing: 0.2em;
    color: var(--color-heading);
    white-space: nowrap;
    font-family: 'Neutral Face', sans-serif;
}

@media (min-width: 48rem) {
    .text-button-circle {
        --size: 16rem;
    }
}

@media (min-width: 75rem) {
    .text-button-circle {
        --size: 18rem;
    }
}

@keyframes rotate-text {
    to {
        transform: rotate(-360deg);
    }
}
