.container {
    --inset: var(--space-scale-s);
    --max-width: min(calc(var(--measure) * 2), calc(100% - var(--inset) * 2));
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--max-width);
    padding-left: var(--inset);
    padding-right: var(--inset);
}
