.button {
    --size: 9rem;

    width: var(--size);
    height:  var(--size);
    border-radius: 50%;
    position: relative;
    font-family: 'Neutral Face';
    font-size: var(--type-scale-s);
    font-weight: 700;
    line-height: var(--size);
    letter-spacing: 0.2em;
    text-align: center;
    color: var(--color-heading);
    white-space: nowrap;
    display: block;
    z-index: 1;

    span,
    &::before,
    &::after {
        display: block;
        width: var(--size);
        height: var(--size);
        line-height: var(--size);
        transition: transform 400ms cubic-bezier(0.19, 1, 0.22, 1);
    }

    &:hover {
        color: var(--color-background);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        border: 1px solid var(--color-heading);
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: var(--color-heading);
        transform: scale3d(0, 0, 1);
        z-index: -1;
    }

    &:hover::before {
        transform: scale3d(0.9, 0.9, 0.9);
    }

    &:hover::after {
        transform: scale3d(1, 1, 1);
    }
}

@media (min-width: 48rem) {
    .button {
        --size: 10rem;
    }
}

@media (min-width: 75rem) {
    .button {
        --size: 11rem;
    }
}

.button-invert {
    color: var(--color-heading-invert);
    &:hover {
        color: var(--color-heading);
    }
    &::before {
        background-color: var(--color-heading);
    }
    &::after {
        background-color: var(--color-heading-invert);
    }
}
