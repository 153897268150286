.contact {
    --cover-min-height: 0;

    position: relative;
}

.contact-form {
    position: relative;
}

.contact-form,
.contact-form-line,
.contact-headline,
.contact-info {
    grid-column: 1 / -1;
    min-width: 100%;
}

.contact-form-icon {
    display: none;
}

.contact-form-hidden {
    display: none;
}

.contact-form-line {
    position: relative;

    + .contact-form-line {
        margin-top: var(--space-scale-l3);
    }

    label,
    input,
    textarea {
        display: block;
        line-height: var(--type-leading-m);
        min-width: 100%;
    }

    label {
        margin-left: var(--space-scale-s3);
        margin-top: var(--space-scale-s3);
    }

    input,
    textarea {
        font-size: var(--type-scale-l);
        line-height: var(--type-leading-l);
        padding-left: var(--space-scale-s3);
        padding-right: var(--space-scale-s3);
    }

    textarea {
        overflow: auto;
        resize: none;
    }

    input:invalid ~ .contact-form-line-deco,
    textarea:invalid ~ .contact-form-line-deco {
    }

    input:invalid ~ .contact-form-line-hint,
    textarea:invalid ~ .contact-form-line-hint {
        display: block;
    }

    &:focus .contact-form-line-deco,
    &:focus-within .contact-form-line-deco {
        transform: scale3d(1, 2, 1);
    }
}

.contact-form-hint,
.contact-form-error,
.contact-form-success {
    margin-top: var(--space-scale-m);
    grid-column: 1 / -1;
    justify-self: end;
}

.contact-form-success {
    color: var(--color-heading);
    color: #CCFFBD;
}

.contact-form-line-deco {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: -1;
    background-color: var(--color-decoration);
    margin-top: 0;
}

.contact-form-line-hint {
    position: absolute;
    top: 100%;
    left: 0;
    font-size: var(--type-scale-s);
    line-height: var(--type-leading-s);
    margin-left: var(--space-scale-s2);
    display: none;
}

.contact-form-submit {
    display: block;
    grid-column: 1 / -1;
    justify-self: end;
    cursor: pointer;
    margin-top: var(--space-scale-l);
    &[disabled] {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.18;
    }
}

.contact-info-link {
    display: inline-block;
    font-family: 'Neutral Face';
    font-size: var(--type-scale-l);
    line-height: var(--type-leading-l);
    letter-spacing: 0.2em;
}


@media (min-width: 48rem) {
    .contact-headline {
        grid-row: 1;
        grid-column: 2 / span 10;
    }

    .contact-info {
        grid-row: 2;
        grid-column: 3 / span 10;
    }

    .contact-form {
        grid-row: 3;
        grid-column: 3 / -3;
    }

    .contact-form-icon {
        --size: var(--space-scale-s2);

        width: var(--size);
        height: var(--size);
        line-height: var(--type-leading-l);
        grid-row: 2;
        grid-column: 2 / span 1;
        display: inline;
        align-self: start;
        fill: var(--color-decoration);
    }
}

@media (min-width: 60rem) {
    .contact-form {
        align-items: start;
        column-gap: var(--space-scale-s);
    }
}
