@use '../settings' as *;

@include spacings using ($name) {
    .pt-#{$name} {
        padding-top: var(--space-scale-#{$name});
    }
    .pr-#{$name} {
        padding-right: var(--space-scale-#{$name});
    }
    .pb-#{$name} {
        padding-bottom: var(--space-scale-#{$name});
    }
    .pl-#{$name} {
        padding-left: var(--space-scale-#{$name});
    }
    .px-#{$name} {
        padding-left: var(--space-scale-#{$name});
        padding-right: var(--space-scale-#{$name});
    }
    .py-#{$name} {
        padding-top: var(--space-scale-#{$name});
        padding-bottom: var(--space-scale-#{$name});
    }
    .p-#{$name} {
        padding: var(--space-scale-#{$name});
    }
}
