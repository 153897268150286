.switcher {
    --threshold: var(--measure);

    display: flex;
    flex-wrap: wrap;
}

.switcher > * {
    flex-grow: 1;
    flex-basis: calc((var(--threshold) - 100%) * 999);
}

// quantity query
.switcher > :nth-last-child(n+4),
.switcher > :nth-last-child(4) ~ *{
    flex-basis: 100%;
}
