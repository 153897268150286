.privacy-list {
    > li {
        margin-left: var(--space-scale-s3);
    }
    > li::marker {
        font-family: 'Neutral Face', sans-serif;
        font-size: var(--type-scale-l);
    }
}

ol.privacy-list {
    > li::before {
        font-family: 'Neutral Face', sans-serif;
        letter-spacing: 0.1em;
    }
}

ul.privacy-list {
    list-style: none;
    > li::before {
        --size: var(--type-scale-m);
        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 192'%3E%3Cpath fill-rule='evenodd' fill='rgba(255,255,255,0.7)' d='M161.003 27c2.207 0 3.997 1.7895 3.997 3.9969v77.7211c0 2.207-1.789 3.997-3.997 3.997-2.207 0-3.997-1.79-3.997-3.997V40.6462L33.8231 163.829c-1.5609 1.561-4.0916 1.561-5.6524 0-1.5609-1.561-1.5609-4.091 0-5.652L151.354 34.9938H83.282c-2.2074 0-3.9969-1.7895-3.9969-3.9969S81.0746 27 83.282 27h77.721z'/%3E%3C/svg%3E");
        display: inline-block;
        width: var(--size);
        height: var(--size);
        margin-right: var(--space-scale-s3);
    }
}

.privacy-text {
    ul,
    ol,
    li,
    p {
        max-width: calc(var(--measure) * 1.5);
    }
}
