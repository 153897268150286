.footer {
    position: relative;
}

.footer-grid,
.footer-middle {
    row-gap: var(--space-scale-l);
}

.footer-scroll-top {
    --inset: var(--space-scale-s);
    position: absolute;
    top: var(--inset);
    right: var(--inset);
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: var(--space-scale-s3);
}

.footer-scroll-top-inner {
    display: block;
    white-space: nowrap;
    writing-mode: vertical-rl;
}

.footer-scroll-top-arrow {
    --size: var(--type-scale-m);
    width: var(--size);
    height: var(--size);
    display: block;
}

.footer-top,
.footer-middle,
.footer-bottom,
.footer-top-heading,
.footer-top-button,
.footer-middle-services,
.footer-middle-services-item,
.footer-middle-company,
.footer-bottom-links,
.footer-bottom-copy {
    grid-column: 1 / -1;
}

.footer-top-heading {
    grid-column: 1 / span 4;
}

.footer-top-line {
    display: none;
}

.footer-middle-company-link {
    font-family: 'Neutral Face';
    letter-spacing: 0.2em;
    font-size: var(--type-scale-m);
    margin-top: var(--space-scale-s);
}

.footer-middle-services {
    row-gap: var(--space-scale-s);
}

.footer-middle-services-item {
    grid-column: span 6;
}

.footer-bottom {
    row-gap: var(--space-scale-s);
}

.footer-bottom-links,
.footer-bottom-copy {
    column-gap: var(--space-scale-s);
}

.footer-bottom-link {
    display: inline-block;
}

@media (min-width: 48rem) {
    .footer-top-button {
        grid-column: 9 / span 5;

        .button {
            --size: 10rem;
        }
    }

    .footer-middle-services-item {
        grid-column: span 4;
    }

    .footer-middle-company {
        grid-column: span 6;
    }

    .footer-bottom-links {
        grid-column: 1 / span 6;
    }

    .footer-bottom-copy {
        grid-column: 9 / span 6;
    }
}

@media (min-width: 60rem) {
    .footer-grid {
        row-gap: var(--space-scale-l3);
    }

    .footer-middle {
        row-gap: 0;
    }

    .footer-top-heading,
    .footer-top-button {
        grid-row: 1;
    }

    .footer-top-line {
        display: block;
        grid-row: 1;
        grid-column: 5 / span 4;
        width: 100%;
        max-width: none;
        height: 1px;
        transform-origin: 0 0;
        align-self: center;
        overflow: visible;

        path {
            fill: none;
            stroke: var(--color-heading);
            stroke-width: 1px;
        }
    }

    .footer-top-button {
        align-self: center;
        grid-column: 10 / span 3;
    }

    .footer-middle-company {
        grid-row: 2;
        grid-column: 1 / span 5;
        margin-top: 0;
    }

    .footer-middle-company-link {
        margin-top: calc(var(--space-scale-s) + var(--type-leading-m));
    }

    .footer-middle-services {
        grid-row: 2;
        grid-column: 7 / span 6;
    }

    .footer-middle-services-item {
        grid-column: span 6;
    }

    .footer-bottom-copy {
        grid-column: 9 / span 4;
    }
}
