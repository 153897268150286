.nav {
    --inset: var(--space-scale-s);
    position: relative;
    z-index: 100;
}

.nav-toggle,
.nav-company {
    position: fixed;
    top: var(--inset);
    line-height: var(--type-leading-m);
}

.nav-company {
    left: var(--inset);
}

.nav-toggle {
    right: var(--inset);
    z-index: 300;

    &.open {
        color: var(--color-decoration-invert);
    }
}

.nav-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    z-index: 200;
    background-color: var(--color-background-invert);
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: var(--inset);
    padding-top: var(--space-scale-l5);
    overflow-y: auto;
}

.nav-eye {
    position: fixed;
    top: var(--inset);
    left: var(--inset);
}

.nav-link,
.nav-secondary,
.nav-eye,
.nav-info {
    color: var(--color-heading-invert);
}

.nav-link-inner,
.nav-secondary-inner {
    display: block;
}

.nav-link-inner {
    font-family: 'Neutral Face', sans-serif;
    letter-spacing: 0.2em;
    font-size: var(--type-scale-l3);
    line-height: var(--type-leading-l3);
}

.nav-link-number,
.nav-secondary-number {
    --offset: calc(var(--type-leading-l3) * 0.5 + var(--type-scale-s) * 0.5);
    display: block;
    font-size: var(--type-scale-s);
    line-height: var(--type-leading-s);
    text-align: right;
    margin-left: auto;
    transform: translate3d(0, var(--offset), 0);
}

.nav-link + .nav-link {
    margin-top: var(--space-scale-s2);
}

.nav-link + .nav-secondary {
    margin-top: var(--space-scale-m);
    letter-spacing: 0.1em;
}

.nav-secondary + .nav-secondary {
    margin-top: var(--space-scale-s3);
}

.eye {
    --size: var(--space-scale-m);

    flex: 0 0 var(--size);
    width: var(--size);
    height: var(--size);

    fill: var(--color-decoration-invert);
}

.eye-inner,
.eye-outer,
.eye-lashes-up,
.eye-lashes-down {
    stroke: var(--color-decoration-invert);
    fill: none;
    stroke-width: 1.5px;
}

.eye-lashes-down {
    opacity: 0;
}

.eye:hover {
    .eye-inner,
    .eye-iris,
    .eye-lashes-up {
        animation: blinkHide 2s step-end infinite;
    }

    .eye-lashes-down {
        animation: blinkShow 2s step-end infinite;
    }
}

@keyframes blinkHide {
	0% {
		opacity: 0;
	}

	10% {
		opacity: 1;
	}
}

@keyframes blinkShow {
	0% {
		opacity: 1;
	}

	10% {
		opacity: 0;
	}
}

@media (min-width: 60rem) {
    .nav-toggle {
        display: none;
    }

    .nav {
        box-sizing: content-box;
        margin-left: auto;
        margin-right: auto;
        max-width: calc(var(--measure) * 2);
        padding-left: var(--space-scale-s);
        padding-right: var(--space-scale-s);
        justify-content: space-between;
    }

    .nav,
    .nav-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .nav-company,
    .nav-menu,
    .nav-eye {
        position: static;
        top: 0;
        left: 0;
    }

    .nav-menu {
        height: var(--type-leading-l2);
        padding: 0;
        background: transparent;
    }

    .nav-link,
    .nav-secondary {
        color: var(--color-paragraph);
    }
    
    .nav-secondary {
        display: none;
    }

    .nav-link-inner,
    .nav-secondary-inner {
        display: block;
        font-size: var(--type-scale-m);
        line-height: var(--type-leading-m);
        font-family: 'Noto Sans', serif;
    }

    .nav-link-number {
        display: none;
    }

    .nav-link + .nav-link,
    .nav-link + .nav-secondary,
    .nav-secondary + .nav-secondary {
        margin-top: 0;
        margin-left: var(--space-scale-s2);
    }

    .nav-eye {
        margin-right: var(--space-scale-l);
        cursor: pointer;
    }

    .eye {
        fill: var(--color-decoration);
    }

    .eye-inner,
    .eye-outer,
    .eye-lashes-up,
    .eye-lashes-down {
        stroke: var(--color-decoration);
    }
}

@media (min-width: 75rem) {
    .nav,
    .nav-menu {
        overflow: visible;
    }
    .nav-link + .nav-link {
        margin-left: var(--space-scale-s);
    }

    .nav-eye {
        margin-right: var(--space-scale-l5);
    }

    .nav-company,
    .nav-link-inner {
        position: relative;
    }

    .nav-company::after,
    .nav-link-inner::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: 1px;
        background-color: currentcolor;
        transform: scale3d(0, 1, 1);
        transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
        transform-origin: 100% 0%;
    }

    .nav-link:hover {
        .nav-link-inner::after {
            transform: scale3d(1, 1, 1);
            transform-origin: 0% 0%;
        }
    }

    .nav-company:hover::after {
        transform: scale3d(1, 1, 1);
        transform-origin: 0% 0%;
    }
}

@media (min-width: 75rem) {
    .nav-company,
    .nav-link-inner,
    .nav-secondary-inner {
        font-size: var(--type-scale-m);
        letter-spacing: -0.0025em;
    }
}
