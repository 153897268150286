.section {
    padding-top: var(--space-scale-l4);
    padding-bottom: var(--space-scale-l4);
}

.section-top {
    padding-top: var(--space-scale-l4);
}

.section-bottom {
    padding-bottom: var(--space-scale-l4);
}

@media (min-height: 60rem) {
    .section {
        padding-top: var(--space-scale-l5);
        padding-bottom: var(--space-scale-l5);
    }
    .section-top {
        padding-top: var(--space-scale-l5);
    }
    .section-bottom {
        padding-bottom: var(--space-scale-l5);
    }
}
