@use '../settings' as *;

@include spacings using ($name) {
    .gap-#{$name} {
        gap: var(--space-scale-#{$name});
    }
    .row-gap-#{$name} {
        row-gap: var(--space-scale-#{$name});
    }
    .col-gap-#{$name} {
        column-gap: var(--space-scale-#{$name});
    }
}
