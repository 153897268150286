* {
    max-width: var(--measure);
}

html,
body,
div,
a,
aside,
section,
header,
section,
nav,
main,
footer,
canvas {
    max-width: none;
}

html {
    background-color: var(--color-background);
    color: var(--color-paragraph);
}

body {
    overflow-x: hidden;
    cursor: default;
}

img {
    display: block;
    height: auto;
}

a {
    cursor: pointer;
}

em,
i {
    font-style: italic;
}

strong,
b {
    font-weight: 700;
}

ins {
    text-decoration: underline;
}

del {
    text-decoration: line-through;
}

hr {
    min-width: 100%;
    height: 1px;
    background-color: var(--color-decoration);
    transform: translate3d(0, -1px, 0);
    grid-column: span 12;
}

ol {
    list-style: none;
    counter-reset: list;

    > li {
        counter-increment: list;
        &::before {
            content: "0"counter(list)".";
        }
    }
}

ul {
    list-style: disc;
    > li::marker {
        color: var(--color-decoration);
    }
}
