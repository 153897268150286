.approach {
    position: relative;
}

.approach-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.approach-header-arrow {
    --size: var(--space-scale-s);
    width: var(--size);
    height: var(--size);
    fill: var(--color-heading);
    display: inline-block;
}

.approach-item-prefix {
    position: relative;
    line-height: var(--type-leading-m);
}

.approach-item,
.approach-item-prefix,
.approach-item-number,
.approach-item-title,
.approach-item-text {
    grid-column: span 12;
}

.approach-item-line {
    display: none;
}

@media (min-width: 60rem) {
    .approach-item-prefix,
    .approach-item-title {
        vertical-align: baseline;
    }

    .approach-item-prefix {
        grid-row: 1;
        grid-column: 4 / span 1;
        transition: transform 300ms cubic-bezier(0.47, 0, 0.745, 0.715);
    }

    .approach-item-line {
        display: block;
        height: 1px;
        background-color: var(--color-decoration);
        align-self: center;
        transform-origin: 0 0;
        grid-column: 1 / span 2;
    }

    .approach-item-title {
        grid-row: 1;
        white-space: nowrap;
    }

    .approach-item-text {
        grid-row: 2;
    }

    .approach-item-title,
    .approach-item-text {
        grid-column: 6 / span 7;
    }
}
