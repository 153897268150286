
.about {
    position: relative;
}

.values {
    position: relative;
}

.values-icon,
.mission-icon {
    display: none;
}

.values-item {
    grid-column: span 12;
}

.mission-item {
    grid-column: span 12;
}

@media (min-width: 60rem) {
    .mission-item:nth-of-type(1) {
        grid-column: 1 / span 6;
    }

    .mission-item:nth-of-type(3) {
        grid-column: 6 / span 7;
    }

    .mission-icon,
    .values-icon {
        --size: var(--space-scale-s);

        width: var(--size);
        height: var(--size);
        display: block;
        align-self: end;

        fill: var(--color-decoration);
    }

    .mission-icon {
        grid-column: 12 / span 1;
    }

    .values-icon {
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    .values-item {
        grid-column: 6 / span 7;
    }

    .values-button {
        grid-row: 1 / span 1;
        grid-column: 1 / span 5;
        align-self: center;
    }
}
