@use '../settings' as *;

.stack {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

@include spacings using ($name) {
    .stack-#{$name} > * + * {
        margin-top: var(--space-scale-#{$name});
    }
}
