.marquee {
    --width: 70vw;

    width: var(--width);
    position: relative;
    z-index: 1;
    text-shadow: var(--width) 0 currentcolor,
        calc(var(--width) * 2) 0 currentcolor,
        calc(var(--width) * 3) 0 currentcolor,
        calc(var(--width) * 4) 0 currentcolor;
    will-change: transform;
    white-space: nowrap;
}

.marquee-left {
    animation: marquee-left 17s linear infinite;
}

.marquee-right {
    animation: marquee-right 20s linear infinite;
}

@media (min-width: 60rem) {
    .marquee {
        --width: 60vw;
    }
}

@media (min-width: 75rem) {
    .marquee {
        --width: 50vw;
    }
}

@media (min-width: 80rem) {
    .marquee {
        --width: 40vw;
    }
}

@keyframes marquee-left {
    to {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes marquee-right {
    from {
        transform: translate3d(-100%, 0, 0);
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
