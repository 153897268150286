.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.heading-inner {
}

.heading-icon {
    --size: var(--type-leading-l);
    width: var(--size);
    height: var(--size);
    fill: var(--color-heading);
    display: inline-block;
}
