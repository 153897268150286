.page-transition {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-heading);
    z-index: 2000;
    pointer-events: none;
    transform: translate3d(0, 0, 0);
}

.page-transition-loader {
    display: block;
}
