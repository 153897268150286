.contact-banner {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, auto);
    padding-top: var(--space-scale-l);
    padding-bottom: var(--space-scale-l);
    row-gap: var(--space-scale-s3);
}

.contact-banner-marquee {
    grid-column: 1 / span 5;
    pointer-events: none;
}

.contact-banner-marquee:nth-of-type(1) {
    grid-row: 1;
    --width: 65vw;
}

.contact-banner-marquee:nth-of-type(2) {
    grid-row: 2;
    --width: 75vw;
}

.contact-banner-button {
    position: relative;
    display: block;
    grid-column: 3 / span 1;
    grid-row: 1 / span 2;
    justify-self: center;
    align-self: center;
    z-index: 1;
}

.contact-banner-button-background {
    position: absolute;
    top: -100%;
    left: -100%;
    display: block;
    width: 300%;
    height: 300%;
    border-radius: 50%;
    background-color: var(--color-heading);
    z-index: -1;

    &::after {
        content: '';
        position: absolute;
        top: -12.5%;
        left: -12.5%;
        width: 125%;
        height: 125%;
        border-radius: 50%;
        background-color: var(--color-background);
        transition: transform 400ms cubic-bezier(.34,1.56,.69,1.26);
        transform: scale(0);
    }
}

.contact-banner-button-inner {
    --size: var(--type-leading-m);
    display: block;
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration-invert);
}

.contact-banner-button.magnetic {
    .contact-banner-button-inner {
        fill: var(--color-decoration);
    }
    .contact-banner-button-background::after {
        transform: scale(1);
    }
}
