@mixin cursor {
    position: fixed;
    width: var(--size);
    height: var(--size);
    top: calc(var(--size) / -2);
    left: calc(var(--size) / -2);
    pointer-events: none;
    z-index: 1000;
}

.custom-trail,
.button-trail {
    display: none;
}

@media (min-width: 60rem) {
    .custom-trail {
        --size: 1rem;
        @include cursor;

        display: block;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: transform 100ms linear;
            background-color: var(--color-heading);
            opacity: 0.18;
        }

        &.hidden {
            display: none;
        }

        &.on-link::before {
            color: var(--color-primary);
            transform: scale3d(4, 4, 4);
        }

        &.on-services::before {
            transform: scale3d(4, 4, 4);
        }

        &.on-button::before {
            background-color: var(--color-background);
            transform: scale3d(6, 6, 6);
        }

        &.on-submit::before {
            transform: scale3d(6, 6, 6);
        }
    }

    .button-trail {
        --size: 4rem;
        @include cursor;

        display: block;
        top: -4rem;
        left: 0;
        mix-blend-mode: difference;

        &.hidden {
            display: none;
        }
    }
}
