@use '../mixins' as *;
@use '../functions' as *;

body {
    font-family: 'Noto Sans', sans-serif;
    font-size: var(--type-scale-m);
    line-height: var(--type-leading-m);
    color: var(--color-paragraph);
}

@include headings {
    font-family: 'Neutral Face', sans-serif;
    color: var(--color-heading);
}

.display {
    font-size: var(--type-scale-display);
    line-height: var(--type-leading-display);
}

.display-long {
    font-size: var(--type-scale-display-long);
    line-height: var(--type-leading-display-long);
}

h1,
.h1 {
    font-size: var(--type-scale-l3);
    line-height: var(--type-leading-l3);
    font-weight: 400;
    letter-spacing: 0.1em;
}

h2,
.h2 {
    font-size: var(--type-scale-l2);
    line-height: var(--type-leading-l2);
    font-weight: 400;
    letter-spacing: 0.1em;
}

h3,
.h3 {
    font-size: var(--type-scale-l);
    line-height: var(--type-leading-l);
    font-weight: 400;
    letter-spacing: 0.1em;
}

h4,
.h4 {
    font-size: var(--type-scale-m);
    line-height: var(--type-leading-m);
    font-weight: 700;
    letter-spacing: 0.25em;
}

small,
.small {
    font-size: var(--type-scale-s2);
    line-height: var(--type-leading-s2);
    letter-spacing: 0.08em;
}
