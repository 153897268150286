.services {
    position: relative;
}

.services-header-wrapper {
    margin-bottom: var(--space-scale-l4);
}

.services-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.services-header-arrow {
    --size: var(--space-scale-s);
    width: var(--size);
    height: var(--size);
    fill: var(--color-heading);
    display: inline-block;
}

.service-list-item {
    padding-block-start: var(--space-scale-l2);
    padding-block-end: var(--space-scale-l2);
    border-top: 1px solid var(--color-decoration);
    &:last-of-type {
        border-bottom: 1px solid var(--color-decoration);
    }
}

.service-list-item-hover {
    display: none;
}

.service-list-item-inner,
.service-list-item-hover-inner {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: 100%;
}

.service-list-item-title,
.service-list-item-text,
.service-list-item-icon,
.service-list-item-hover-title,
.service-list-item-hover-text,
.service-list-item-hover-icon {
    grid-column: 1 / span 12;
}

.service-list-item-title,
.service-list-item-hover-title {
    margin-top: var(--space-scale-s);
}

.service-list-item-text,
.service-list-item-hover-text {
    margin-top: var(--space-scale-s3);
}

.service-list-item-icon,
.service-list-item-hover-icon {
    --size: var(--type-leading-m);
    grid-row: 1;
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration);
}

.service-list-item-hover-icon-inner {
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration);
}

@media (min-height: 60rem) {
    .service-header-wrapper {
        margin-bottom: var(--space-scale-l3);
    }
}

@media (min-width: 48rem) {
    .service-list-item-title,
    .service-list-item-hover-title {
        grid-row: 1;
        grid-column: 1 / span 4;
        margin-top: 0;
    }
    .service-list-item-text,
    .service-list-item-hover-text {
        grid-row: 1;
        grid-column: 6 / span 5;
        margin-top: 0;
    }
    .service-list-item-icon,
    .service-list-item-hover-icon {
        grid-row: 1;
        grid-column: 12 / span 1;
        justify-self: end;
    }
}

@media (min-width: 75rem) {
    .service-list-item {
        position: relative;
        overflow: hidden;
    }
    .service-list-item-hover {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-background-invert);
        pointer-events: none;
        transform: translate3d(0, 101%, 0);
        will-change: transform;
    }
    .service-list-item-hover-decoration {
        position: absolute;
        top: 0;
        left: 0;
        width: min-content;
        white-space: nowrap;
        color: var(--color-decoration-invert);
        font-size: var(--type-scale-s);
        line-height: var(--type-leading-s);
    }
    .service-list-item-hover-inner-wrapper {
        width: 100%;
        height: 100%;
        padding-block-start: var(--space-scale-l2);
        padding-block-end: var(--space-scale-l2);
    }
    .service-list-item-hover-title {
        color: var(--color-decoration-invert);
    }
    .service-list-item-hover-text {
        color: var(--color-heading-invert);
    }
    .service-list-item-hover-icon {
        position: relative;
        align-self: center;
    }
    .service-list-item-hover-icon-background {
        position: absolute;
        top: -100%;
        left: -100%;
        display: block;
        width: 300%;
        height: 300%;
        border-radius: 50%;
        border: 1px solid var(--color-decoration-invert);
        transform: scale(0);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: var(--color-background);
            transform: scale(0);
        }
    }
    .service-list-item-hover-icon-inner {
        position: relative;
        z-index: 1;
        fill: var(--color-decoration-invert);
    }
    .service-list-item-hover-icon.magnetic {
        .service-list-item-hover-icon-background::after {
            transition: transform 400ms cubic-bezier(.34,1.56,.69,1.26);
            transform: scale(1);
        }
        .service-list-item-hover-icon-inner {
            fill: var(--color-decoration);
        }
    }
}
