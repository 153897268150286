.link {
    position: relative;
    display: inline-block;
}

.link::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: 1px;
    background-color: currentcolor;
    transform: scale3d(0, 1, 1);
    transition: transform 400ms cubic-bezier(0.86, 0, 0.07, 1);
    transform-origin: 100% 0%;
}

.link:hover::after {
    transform: scale3d(1, 1, 1);
    transform-origin: 0% 0%;
}
