.step-item {
    padding-block-start: var(--space-scale-l);
    padding-block-end: var(--space-scale-l);
}

.step-item-title,
.step-item-text,
.step-item-number {
    grid-column: 1 / span 12;
}

.step-item-number {
    font-family: 'Neutral Face';
    line-height: var(--type-leading-l);
    grid-row: 1;
    color: var(--color-decoration);
}

.step-item-title {
    line-height: var(--type-leading-l);
    color: var(--color-decoration);
}

@media (min-width: 48rem) {
    .step-item {
        padding-block-start: var(--space-scale-l3);
        padding-block-end: var(--space-scale-l3);
    }

    .step-item-title {
        grid-column: 1 / span 10;
    }

    .step-item-number {
        grid-column: 12 / span 1;
    }
}

@media (min-width: 60rem) {
    .step-item-title {
        grid-column: 2 / span 4;
    }

    .step-item-text {
        grid-column: 1 / span 12;
    }

    .step-item-number {
        grid-row: 3;
    }
}
