@use '../settings' as *;

.cover {
    --cover-min-height: 70vh;

    //display: flex;
    //flex-direction: column;
    min-height: var(--cover-min-height);
}

.cover > .cover-center {
    margin-top: auto;
    margin-bottom: auto;
}

.cover > :first-child:not(.cover-center) {
    margin-top: 0;
}

.cover > :last-child:not(.cover-center) {
    margin-bottom: 0;
}

@include spacings using ($name) {
    .cover-#{$name} > * {
        margin-top: var(--space-scale-#{$name});
        margin-bottom: var(--space-scale-#{$name});
    }
}
