.home-header {
    min-height: 100vh;
    padding-top: var(--space-scale-l3);
    padding-bottom: var(--space-scale-l);
}

.home-header.grid {
    grid-auto-rows: minmax(min-content, max-content);
    align-content: end;
    row-gap: var(--space-scale-m);
}

.home-header-sphere,
.home-header-title,
.home-header-subtitle,
.home-header-line,
.home-header-cta {
    grid-column: 1 / span 12;
}

.home-header-scroll {
    display: none;
}

.home-header-title {
    grid-row: 1;
    font-size: var(--type-scale-l2);
    line-height: var(--type-leading-l3);
    max-width: 20ch;
}

.home-header-sphere {
    --size: var(--measure);
    position: relative;
    aspect-ratio: 1/1;
    grid-row: 2;
    max-width: var(--size);
    canvas {
        max-width: var(--size);
        max-height: var(--size);
    }
}

.home-header-subtitle {
    grid-row: 3;
}

.home-header-line {
    grid-row: 4;
    grid-column: 1 / span 4;
    height: 1px;
    align-self: center;
    max-width: none;
    overflow: visible;

    path {
        fill: none;
        stroke: var(--color-decoration);
        stroke-width: 1px;
    }
}

.home-header-cta {
    grid-row: 4;
    grid-column: 5 / span 3;
    justify-self: end;
}

@media (min-width: 48rem) {
    .home-header {
        padding-top: var(--space-scale-m);
    }
    .home-header-title {
        grid-column: 1 / span 9;
    }
    .home-header-sphere {
        grid-column: 3 / -3;
    }
    .home-header-subtitle {
        grid-column: 8 / span 5;
        justify-self: end;
    }
    .home-header-line {
        grid-row: 3;
        grid-column: 3 / span 4;
        align-self: center;
    }
    .home-header-cta {
        grid-row: 3;
        grid-column: 1 / span 2;
        align-self: center;
        justify-self: start;
        transform: translate3d(0, -1px, 0);
    }
    .home-header-scroll {
        display: block;
        grid-row: 1 / span 2;
        grid-column: 11 / span 2;
        align-self: center;
        justify-self: end;
    }
    .home-header-scroll-text {
        font-size: var(--type-scale-m);
        line-height: var(--space-scale-s);
        writing-mode: vertical-rl;
        margin-bottom: var(--space-scale-s2);
        letter-spacing: 0.1em;
    }
    .home-header-arrow {
        --size: var(--space-scale-s);
        display: block;
        width: var(--size);
        height: var(--size);
        fill: var(--color-decoration);
    }
}

@media (min-width: 60rem) {
    .home-header {
        min-height: calc(100vh - var(--type-leading-l2));
    }
    .home-header-sphere {
        grid-column: 2 / -2;
        --size: calc(var(--measure) * 1.15);
    }
}

@media (min-width: 60rem) and (max-height: 75rem) {
    .home-header.grid {
        align-content: space-between;
    }
    .home-header-title {
        grid-row: 1 / span 1;
        line-height: var(--type-leading-l2);
    }
    .home-header-sphere {
        grid-row: 1 / span 3;
        grid-column: 5 / span 6;
        align-self: end;
    }
    .home-header-subtitle {
        grid-column: 1 / span 4;
        align-self: end;
    }
    .home-header-line {
        grid-column: 5 / span 5;
        align-self: end;
        transform: translate3d(0, calc(var(--type-leading-m) * -0.5 + 1px), 0);
    }
    .home-header-cta {
        grid-column: 11 / span 2;
        align-self: end;
        transform: none;
    }
}

@media (min-width: 60rem) and (max-height: 60rem) {
    .home-header {
        padding-top: var(--space-scale-l2);
        padding-bottom: var(--space-scale-s);
    }
}

@media (min-width: 60rem) and (min-height: 60rem) and (max-height: 75rem) {
    .home-header {
        padding-top: var(--space-scale-l4);
        padding-bottom: var(--space-scale-l);
    }
    .home-header-sphere {
        align-self: center;
    }
}

@media (min-width: 75rem) and (min-height: 75rem) {
    .home-header {
        padding-top: var(--space-scale-l4);
        padding-bottom: var(--space-scale-l);
    }
    .home-header.grid {
        align-content: space-between;
    }
    .home-header-title {
        grid-row: 1 / span 1;
        line-height: var(--type-leading-l2);
    }
    .home-header-sphere {
        grid-row: 1 / span 3;
        grid-column: 5 / span 6;
        align-self: center;
    }
    .home-header-subtitle {
        grid-column: 1 / span 4;
        align-self: end;
    }
    .home-header-line {
        grid-column: 5 / span 5;
        align-self: end;
        transform: translate3d(0, calc(var(--type-leading-m) * -0.5 + 1px), 0);
    }
    .home-header-cta {
        grid-column: 11 / span 2;
        align-self: end;
        transform: none;
    }
}

@media (min-width: 87.5rem) {
    .home-header-line {
        grid-column: 5 / span 7;
    }
    .home-header-cta {
        grid-column: 12 / span 1;
        justify-self: end;
    }
    .home-header-sphere {
        align-self: end;
        margin-bottom: var(--space-scale-s);
    }
}

@media (min-width: 100rem) {
    .home-header-title {
        font-size: var(--type-scale-l3-long);
    }
}

@media (min-width:87.5rem) and (min-height: 75rem) {
    .home-header-sphere {
        margin-bottom: var(--space-scale-l2);
    }
}
