.center,
.center-intrinsic,
.center-fluid {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--measure);
}

.center-intrinsic {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.center-fluid {
    max-width: 100%;
}
