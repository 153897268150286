.iot {
    position: relative;
}

.iot-animation {
    overflow: hidden;
}

.iot-animation,
.iot-animation-svg {
    min-width: 100%;
    max-width: 100%;
}

.iot-animation-svg text {
    font-family: 'Noto Sans', sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: var(--type-leading-m);
}

.iot-sensor-icon {
    fill: #fff;
    stroke: #000;
    stroke-width: 1px;
}

.iot-sensor-icon-inner {
    fill: #000;
    stroke: #000;
    stroke-linecap: round;
}

.iot-sensor-icon-center {
    fill: #fff;
    stroke: #000;
    stroke-linecap: square;
}

.iot-sensor-icon-path {
    fill: none;
    stroke: #000;
    stroke-linecap: round;
}

.iot-sensor-data {
    fill: #FF165D;
    stroke: #26001B;
}

.iot-config-data {
    fill: #FF9A00;
    stroke: #26001B;
}

.iot-alert-data {
    fill: #FF165D;
    stroke: #26001B;
}

.iot-alert-data text {
    fill: #3b0000;
    stroke: #3b0000;
}

.iot-action-data {
    fill: #C0E218;
    stroke: #26001B;
}

.iot-cmd-data {
    fill: #3EC1D3;
    stroke: #26001B;
}

.iot-connection {
    fill: none;
    stroke: #d3d3d3;
    stroke-width: 1px;
}

.iot-router {
    fill: #fff;
    stroke: #000;
    stroke-width: 1px;
}

.iot-app-btn {
    fill: #3EC1D3;
    stroke: #3EC1D3;
    stroke-width: 1px;
    stroke-linecap: round;
}

.iot-app-btn-dark {
    fill: #fff;
    stroke: #000;
    stroke-width: 1px;
    stroke-linecap: round;
}

.iot-app-slider {
    fill: #000;
    stroke: #000;
    stroke-width: 1px;
    stroke-dasharray: 4 2 6 2 5;
}

.iot-app-slider-circle {
    fill: #3EC1D3;
    stroke: #000;
    stroke-width: 1px;
}

.iot-ctrl {
    position: relative;
    overflow: hidden;
    display: flex;
}

.iot-ctrl-carousel {
    display: flex;
    gap: var(--space-scale-m);
    justify-content: space-between;
}

.iot-ctrl-carousel-ctrl {
    --size: var(--space-scale-m);
    width: var(--size);
    height: var(--size);
    fill: var(--color-decoration);
    cursor: pointer;
}

.iot-ctrl-item {
    flex: 0 0 100%;
    overflow: hidden;
}

.iot-ctrl-item-head {
    display: flex;
    min-width: 100%;
    border-bottom: 1px solid #000;

    .play {
        display: inline-block;
        background-color: #000;
        padding-left: var(--space-scale-s3);
        padding-right: var(--space-scale-s3);
        cursor: pointer;
        color: #fff;
    }

    .text {
        display: inline-block;
        margin-left: var(--space-scale-s3);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
    }
}

.iot-ctrl-item-desc {
    display: flex;
    align-items: center;
}

.iot-ctrl-item-desc-icon {
    --size: var(--space-scale-s);
    display: block;
    width: var(--size);
    height: var(--size);
    flex: 0 0 var(--size);
    margin-right: var(--space-scale-s3);
}

.iot-ctrl-item-desc-active {
    --size: var(--space-scale-s2);
    display: block;
    width: var(--size);
    height: var(--size);
    flex: 0 0 var(--size);
    margin-left: var(--space-scale-s3);
    transform: translate3d(50%, 0, 0);
    opacity: 0;
}

.iot-ctrl-item-desc.active {
    .iot-ctrl-item-desc-active {
        transition: opacity 200ms ease-out,
            transform 400ms ease;
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

@media (min-width: 60rem) {
    .iot-ctrl-item {
        flex: 0 0 50%;
        &:last-of-type {
            flex: 0 0 100%;
        }
    }
}
